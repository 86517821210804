.chatBox {
    @apply relative flex w-[80%] lg:w-[55%] p-4;
}

.chatBoxArrow {
    @apply absolute bottom-0 border-[44px] border-transparent border-b-0 mb-[-43px] w-0 h-0;
}

.chatBoxQuestion {
    @apply justify-end text-blue bg-white border-4 border-orange-dark cursor-pointer max-w-full w-full mt-3 lg:mt-5 mb-1;
}
