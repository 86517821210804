@import "src/assets/fonts/stylesheet";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -ms-overflow-style: none;
  scrollbar-width: none;

  font-family: "Diodrum";
}

*::-webkit-scrollbar {
  display: none;
}

html {
  @apply md:text-[20px] 2xl:text-[26px] min-[1920px]:text-[30px] min-[2300px]:text-[32px];
}

body {
  @apply relative bg-[rgba(0,0,0,0.2)] w-screen lg:w-full h-svh;
}

ul {
  @apply list-decimal ml-0 pl-8;

  > li {
    @apply mb-2;
  }
}

.main-container {
  @apply flex rounded-br-4xl w-full h-[78%] lg:h-3/4 max-lg:pb-10;
}

#root {
  @apply lg:relative lg:top-1/2 lg:-translate-y-1/2 h-svh lg:h-auto;
}
